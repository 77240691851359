var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',[_c('div',{staticClass:"mb-5"},[_c('v-row',{attrs:{"align":"stretch","no-gutters":""}},[_c('v-col',{staticClass:"grow"},[_c('SearchKeyword',{attrs:{"filter":_vm.filter,"searchable":['name','description']}})],1),(_vm.user.is_superadmin)?_c('v-col',{staticClass:"shrink mt-1 ml-3"},[_c('DashboardListFilter',{attrs:{"filter":_vm.filter}})],1):_vm._e()],1)],1),_c('v-card',[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-icon',[_vm._v("mdi-monitor-dashboard")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-toolbar-title',{staticClass:"d-none d-sm-flex align-center"},[_c('span',[_vm._v(_vm._s(_vm.camelToSentence(_vm.$options.name)))])]),_c('v-spacer'),(_vm.isAdmin)?_c('v-btn',{attrs:{"color":"primary","dark":""},on:{"click":function($event){return _vm.openForm(null)}}},[_vm._v(" Add ")]):_vm._e()],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.meta.total,"loading":_vm.loading,"disable-sort":"","mobile-breakpoint":"0","footer-props":_vm.footerProps},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"body-1 font-weight-bold no-underline",attrs:{"to":("/dashboards/" + (item.id))},domProps:{"innerHTML":_vm._s(_vm.highlightString(item.name,_vm.routeQuery.keyword))}})]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(_vm.highlightString(item.description,_vm.routeQuery.keyword))}})]}},{key:"item.organization",fn:function(ref){
var item = ref.item;
return [(item.organization)?_c('router-link',{staticClass:"body-1 font-weight-bold no-underline",attrs:{"to":("/organizations/" + (item.organization.id))}},[_vm._v(" "+_vm._s(item.organization.name)+" ")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"icon":""},on:{"click":function($event){return _vm.openForm(item)}}},on),[_c('v-icon',[_vm._v(" mdi-pencil ")])],1)]}}],null,true)},[_c('span',[_vm._v("Edit")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"icon":"","color":"error"},on:{"click":function($event){return _vm.deleteItem(item)}}},on),[_c('v-icon',[_vm._v(" mdi-delete ")])],1)]}}],null,true)},[_c('span',[_vm._v("Delete")])])]}}])})],1),_c('DashboardForm',{ref:"formModal",attrs:{"item":_vm.item},on:{"reload-data":function($event){return _vm.getItem()}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }