<template>
  <v-row>
    <v-col>
      <div class="mb-5">
        <v-row
          align="stretch"
          no-gutters
        >
          <v-col class="grow">
            <SearchKeyword
              :filter="filter"
              :searchable="['name','description']"
            />
          </v-col>
          <v-col
            v-if="user.is_superadmin"
            class="shrink mt-1 ml-3"
          >
            <DashboardListFilter :filter="filter" />
          </v-col>
        </v-row>
      </div>

      <v-card>
        <v-toolbar flat>
          <v-icon>mdi-monitor-dashboard</v-icon>
          <v-divider
            class="mx-4"
            inset
            vertical
          />
          <v-toolbar-title class="d-none d-sm-flex align-center">
            <span>{{ camelToSentence($options.name) }}</span>
          </v-toolbar-title>
          <v-spacer />
          <v-btn
            v-if="isAdmin"
            color="primary"
            dark
            @click="openForm(null)"
          >
            Add
          </v-btn>
        </v-toolbar>

        <v-data-table
          :headers="headers"
          :items="items"
          :options.sync="options"
          :server-items-length="meta.total"
          :loading="loading"
          disable-sort
          mobile-breakpoint="0"
          :footer-props="footerProps"
        >
          <!-- name -->
          <template v-slot:item.name="{item}">
            <router-link
              :to="`/dashboards/${item.id}`"
              class="body-1 font-weight-bold no-underline"
              v-html="highlightString(item.name,routeQuery.keyword)"
            />
          </template>
          <!-- description -->
          <template v-slot:item.description="{item}">
            <span v-html="highlightString(item.description,routeQuery.keyword)" />
          </template>
          <!-- organization -->
          <template v-slot:item.organization="{item}">
            <router-link
              v-if="item.organization"
              :to="`/organizations/${item.organization.id}`"
              class="body-1 font-weight-bold no-underline"
            >
              {{ item.organization.name }}
            </router-link>
          </template>
          <!-- actions -->
          <template v-slot:item.actions="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  icon
                  class="mr-2"
                  v-on="on"
                  @click="openForm(item)"
                >
                  <v-icon>
                    mdi-pencil
                  </v-icon>
                </v-btn>
              </template>
              <span>Edit</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  icon
                  color="error"
                  class="mr-2"
                  v-on="on"
                  @click="deleteItem(item)"
                >
                  <v-icon>
                    mdi-delete
                  </v-icon>
                </v-btn>
              </template>
              <span>Delete</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card>

      <DashboardForm
        ref="formModal"
        :item="item"
        @reload-data="getItem()"
      />
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex';
import DatalistMixin from '../../mixins/DatalistMixin';
import DashboardForm from './DashboardForm.vue';
import DashboardListFilter from './DashboardListFilter.vue';
import SearchKeyword from '../../components/SearchKeyword.vue';

export default {
  name: 'DashboardList',
  components: { DashboardForm, SearchKeyword, DashboardListFilter },
  mixins: [DatalistMixin],
  data() {
    return {
      headers: [
        {
          text: 'Name',
          value: 'name',
          class: 'min-width-15',
        },
        {
          text: 'Description',
          value: 'description',
          class: 'min-width-15',
        },
        {
          text: 'Organization',
          value: 'organization',
          class: 'min-width-10',
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          align: 'center',
          width: '120px',
        },
      ],
      filter: {
        page: 1,
        keyword: null,
        organization_id: null,
      },
    };
  },

  computed: {
    ...mapGetters(['user']),

    isAdmin() {
      return this.user.is_superadmin || this.user.is_organization_admin;
    },
  },

  created() {
    if (!this.user.is_superadmin) {
      // hide 'organization' header, when not superadmin
      this.headers = this.headers.filter((h) => h.value !== 'organization');
      // non admin cant use actions
      if (!this.isAdmin) {
        this.headers = this.headers.filter((h) => h.value !== 'actions');
      }
      // non superadmin cant filter by organization
      delete this.filter.organization_id;
    } else {
      // superadmin load all organizations
      this.$store.dispatch('getOrganizations');
    }
  },

  methods: {
    getData() {
      return this.$api.getDashboards(this.filter);
    },

    deleteData(intId) {
      return this.$api.deleteDashboard(intId);
    },
  },
};
</script>
